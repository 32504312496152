import * as Cookies from 'js-cookie'
import router from '@/router'

export const setCookie = (cookie) => {
  Cookies.set('superindo-token', cookie, { path: '/' })
  return {
    token: Cookies.get('superindo-token')
  }
}

export const setCookieProfile = (cookie) => {
  Cookies.set('superindo-profile', cookie, { path: '/' })
  return {
    profile: Cookies.get('superindo-profile')
  }
}

export const getUserToken = () => {
  return Cookies.get('superindo-token')
}

export const getUserProfile = () => {
  return JSON.parse(Cookies.get('superindo-profile'))
}

export const isLogin = () => {
  const token = Cookies.get('superindo-token')
  const profile = Cookies.get('superindo-profile')
  if (token && profile) {
    return true
  }
  return false
}

export const logoutUser = () => {
  Cookies.remove('superindo-token', { path: '/' })
  Cookies.remove('superindo-profile', { path: '/' })
  localStorage.removeItem('role_managements')
  router.go(0)
}
