const BASE_URL_ACCOUNT = `${process.env.VUE_APP_API_URL_ACCOUNT}/dashboard`
const BASE_URL_ANALYTIC = `${process.env.VUE_APP_API_URL_ANALYTIC}/dashboard`
const BASE_URL_LOYALITY = `${process.env.VUE_APP_API_URL_LOYALITY}/dashboard`

// Auth
export const API_AUTH = {
  LOGIN: `${BASE_URL_ACCOUNT}/auth/sign-in`,
  LOGOUT: `${BASE_URL_ACCOUNT}/auth/sign-out`,
  VERIFICATION_LOGIN: `${BASE_URL_ACCOUNT}/auth/sign-in-verification`,
  FORGOT_PASSWORD: `${BASE_URL_ACCOUNT}/auth/forgot-password`,
  CHANGE_PASSWORD: `${BASE_URL_ACCOUNT}/user/change-password`,
  IDLE_SESSION: `${BASE_URL_ACCOUNT}/auth/idle`
}

export const API_CONTENT = {
  COUNTER_BANNER: `${BASE_URL_ANALYTIC}/content-banner/total`,
  COUNTER_BANNER_IMPRESS_CLICK: `${BASE_URL_ANALYTIC}/content-banner/impress-click`,
  BANNER_TOP_IMPRESS: `${BASE_URL_ANALYTIC}/content-banner/top-impress`,
  BANNER_TOP_CLICK: `${BASE_URL_ANALYTIC}/content-banner/top-click`,
  BANNER_BOTTOM_IMPRESS: `${BASE_URL_ANALYTIC}/content-banner/bottom-impress`,
  BANNER_BOTTOM_CLICK: `${BASE_URL_ANALYTIC}/content-banner/bottom-click`,
  EXPORT_COUNTER_BANNER: param => `${BASE_URL_ANALYTIC}/content-banner/total/${param}`,
  EXPORT_BANNER_IMPRESS_CLICK: param => `${BASE_URL_ANALYTIC}/content-banner/impress-click/${param}`,
  EXPORT_BANNER_TOP_IMPRESS: param => `${BASE_URL_ANALYTIC}/content-banner/top-impress/${param}`,
  EXPORT_BANNER_TOP_CLICK: param => `${BASE_URL_ANALYTIC}/content-banner/top-click/${param}`,
  EXPORT_BANNER_BOTTOM_IMPRESS: param => `${BASE_URL_ANALYTIC}/content-banner/bottom-impress/${param}`,
  EXPORT_BANNER_BOTTOM_CLICK: param => `${BASE_URL_ANALYTIC}/content-banner/bottom-click/${param}`,
  SHARE_COUNTER_BANNER: `${BASE_URL_ANALYTIC}/content-banner/total/share`,
  SHARE_BANNER_IMPRESS_CLICK: `${BASE_URL_ANALYTIC}/content-banner/impress-click/share`,
  SHARE_BANNER_TOP_IMPRESS: `${BASE_URL_ANALYTIC}/content-banner/top-impress/share`,
  SHARE_BANNER_TOP_CLICK: `${BASE_URL_ANALYTIC}/content-banner/top-click/share`,
  SHARE_BANNER_BOTTOM_IMPRESS: `${BASE_URL_ANALYTIC}/content-banner/bottom-impress/share`,
  SHARE_BANNER_BOTTOM_CLICK: `${BASE_URL_ANALYTIC}/content-banner/bottom-click/share`,

  COUNTER_MESSAGE: `${BASE_URL_ANALYTIC}/content-pushmessage/total`,
  COUNTER_MESSAGE_IMPRESS_CLICK: `${BASE_URL_ANALYTIC}/content-pushmessage/sent-impress-click`,
  MESSAGE_TOP_IMPRESS: `${BASE_URL_ANALYTIC}/content-pushmessage/top-impress`,
  MESSAGE_TOP_CLICK: `${BASE_URL_ANALYTIC}/content-pushmessage/top-click`,
  MESSAGE_BOTTOM_IMPRESS: `${BASE_URL_ANALYTIC}/content-pushmessage/bottom-impress`,
  MESSAGE_BOTTOM_CLICK: `${BASE_URL_ANALYTIC}/content-pushmessage/bottom-click`,
  EXPORT_COUNTER_MESSAGE: param => `${BASE_URL_ANALYTIC}/content-pushmessage/total/${param}`,
  EXPORT_MESSAGE_IMPRESS_CLICK: param => `${BASE_URL_ANALYTIC}/content-pushmessage/sent-impress-click/${param}`,
  EXPORT_MESSAGE_TOP_IMPRESS: param => `${BASE_URL_ANALYTIC}/content-pushmessage/top-impress/${param}`,
  EXPORT_MESSAGE_TOP_CLICK: param => `${BASE_URL_ANALYTIC}/content-pushmessage/top-click/${param}`,
  EXPORT_MESSAGE_BOTTOM_IMPRESS: param => `${BASE_URL_ANALYTIC}/content-pushmessage/bottom-impress/${param}`,
  EXPORT_MESSAGE_BOTTOM_CLICK: param => `${BASE_URL_ANALYTIC}/content-pushmessage/bottom-click/${param}`,
  SHARE_COUNTER_MESSAGE: `${BASE_URL_ANALYTIC}/content-pushmessage/total/share`,
  SHARE_MESSAGE_IMPRESS_CLICK: `${BASE_URL_ANALYTIC}/content-pushmessage/sent-impress-click/share`,
  SHARE_MESSAGE_TOP_IMPRESS: `${BASE_URL_ANALYTIC}/content-pushmessage/top-impress/share`,
  SHARE_MESSAGE_TOP_CLICK: `${BASE_URL_ANALYTIC}/content-pushmessage/top-click/share`,
  SHARE_MESSAGE_BOTTOM_IMPRESS: `${BASE_URL_ANALYTIC}/content-pushmessage/bottom-impress/share`,
  SHARE_MESSAGE_BOTTOM_CLICK: `${BASE_URL_ANALYTIC}/content-pushmessage/bottom-click/share`,

  LIST_CAMPAIGN: `${BASE_URL_LOYALITY}/campaign/list?is_all=true`,
  LIST_OFFER: `${BASE_URL_LOYALITY}/offer?is_all=true&is_show_delete=false`,
  LIST_OFFER_LIMIT: `${BASE_URL_LOYALITY}/offer`,
  LIST_BANNER: `${BASE_URL_LOYALITY}/banner?is_all=true&is_show_delete=false`,
  LIST_MESSAGE: `${BASE_URL_LOYALITY}/push-message?is_all=true&is_show_delete=false`,
  LIST_STORE: `${BASE_URL_LOYALITY}/store?is_all=true`,
  MARKETING_COUNTER: `${BASE_URL_ANALYTIC}/marketing/count-all`,
  MARKETING_TOP_REDEEM: `${BASE_URL_ANALYTIC}/content-offer/top-redeem`,
  MARKETING_TOP_CLICK: `${BASE_URL_ANALYTIC}/content-banner/top-click`,
  MARKETING_UNIQUE_TRANSACTION: `${BASE_URL_ANALYTIC}/marketing/unique-transaction`,
  MARKETING_SALES: `${BASE_URL_ANALYTIC}/marketing/sales`,
  MARKETING_AVG_TRANSACTION: `${BASE_URL_ANALYTIC}/marketing/avg-transaction`,
  EXPORT_MARKETING_UNIQUE_TRANSACTION: param => `${BASE_URL_ANALYTIC}/marketing/unique-transaction/${param}`,
  EXPORT_MARKETING_SALES: param => `${BASE_URL_ANALYTIC}/marketing/sales/${param}`,
  EXPORT_MARKETING_AVG_TRANSACTION: param => `${BASE_URL_ANALYTIC}/marketing/avg-transaction/${param}`,
  EXPORT_MARKETING_TOP_BANNER: param => `${BASE_URL_ANALYTIC}/content-banner/top-click/${param}`,
  EXPORT_MARKETING_TOP_OFFER: param => `${BASE_URL_ANALYTIC}/content-offer/top-redeem/${param}`,
  EXPORT_MARKETING_COUNTER: param => `${BASE_URL_ANALYTIC}/marketing/count-all/${param}`,
  SHARE_MARKETING_UNIQUE_TRANSACTION: `${BASE_URL_ANALYTIC}/marketing/unique-transaction/share`,
  SHARE_MARKETING_SALES: `${BASE_URL_ANALYTIC}/marketing/sales/share`,
  SHARE_MARKETING_TOP_REDEEM: `${BASE_URL_ANALYTIC}/content-offer/top-redeem/share`,
  SHARE_MARKETING_TOP_CLICK: `${BASE_URL_ANALYTIC}/content-banner/top-click/share`,
  SHARE_MARKETING_AVERAGE: `${BASE_URL_ANALYTIC}/marketing/avg-transaction/share`,
  SHARE_MARKETING_COUNTER: `${BASE_URL_ANALYTIC}/marketing/count-all/share`,

  OVERVIEW_TOTAL: `${BASE_URL_ANALYTIC}/overview/count-all`,
  OVERVIEW_OPEN: `${BASE_URL_ANALYTIC}/overview/consumer-open`,
  OVERVIEW_REGISTRATION: `${BASE_URL_ANALYTIC}/overview/consumer-register`,
  OVERVIEW_ACTIVE: `${BASE_URL_ANALYTIC}/overview/consumer-active`,
  OVERVIEW_DOWNLOAD: `${BASE_URL_ANALYTIC}/overview/consumer-download`,
  OVERVIEW_VISITING: `${BASE_URL_ANALYTIC}/overview/consumer-visit`,
  OVERVIEW_ENGAGED: `${BASE_URL_ANALYTIC}/overview/consumer-engage`,
  OVERVIEW_TRANSACTION: `${BASE_URL_ANALYTIC}/overview/consumer-transaction`,
  OVERVIEW_AVG_ACTIVE: `${BASE_URL_ANALYTIC}/overview/average-consumer-active`,
  OVERVIEW_AVG_VISITING: `${BASE_URL_ANALYTIC}/overview/average-consumer-visit`,
  OVERVIEW_AVG_ENGAGED: `${BASE_URL_ANALYTIC}/overview/average-consumer-engage`,
  OVERVIEW_EXPORT_COUNTER: param => `${BASE_URL_ANALYTIC}/overview/count-all/${param}`,
  OVERVIEW_EXPORT_OPEN: param => `${BASE_URL_ANALYTIC}/overview/consumer-open/${param}`,
  OVERVIEW_EXPORT_REGISTRATION: param => `${BASE_URL_ANALYTIC}/overview/consumer-register/${param}`,
  OVERVIEW_EXPORT_ACTIVE: param => `${BASE_URL_ANALYTIC}/overview/consumer-active/${param}`,
  OVERVIEW_EXPORT_VISITING: param => `${BASE_URL_ANALYTIC}/overview/consumer-visit/${param}`,
  OVERVIEW_EXPORT_DOWNLOAD: param => `${BASE_URL_ANALYTIC}/overview/consumer-download/${param}`,
  OVERVIEW_EXPORT_ENGAGED: param => `${BASE_URL_ANALYTIC}/overview/consumer-engage/${param}`,
  OVERVIEW_EXPORT_TRANSACTION: param => `${BASE_URL_ANALYTIC}/overview/consumer-transaction/${param}`,
  OVERVIEW_EXPORT_AVG_ACTIVE: param => `${BASE_URL_ANALYTIC}/overview/average-consumer-active/${param}`,
  OVERVIEW_EXPORT_AVG_VISITING: param => `${BASE_URL_ANALYTIC}/overview/average-consumer-visit/${param}`,
  OVERVIEW_EXPORT_AVG_ENGAGED: param => `${BASE_URL_ANALYTIC}/overview/average-consumer-engage/${param}`,

  SHARE_COUNTER_OVERVIEW: `${BASE_URL_ANALYTIC}/overview/count-all/share`,
  SHARE_OVERVIEW_OPEN: `${BASE_URL_ANALYTIC}/overview/consumer-open/share`,
  SHARE_OVERVIEW_REGISTRATION: `${BASE_URL_ANALYTIC}/overview/consumer-register/share`,
  SHARE_OVERVIEW_ACTIVE: `${BASE_URL_ANALYTIC}/overview/consumer-active/share`,
  SHARE_OVERVIEW_VISITING: `${BASE_URL_ANALYTIC}/overview/consumer-visit/share`,
  SHARE_OVERVIEW_DOWNLOAD: `${BASE_URL_ANALYTIC}/overview/consumer-download/share`,
  SHARE_OVERVIEW_ENGAGED: `${BASE_URL_ANALYTIC}/overview/consumer-engage/share`,
  SHARE_OVERVIEW_UNIQUE: `${BASE_URL_ANALYTIC}/overview/consumer-transaction/share`,
  SHARE_OVERVIEW_AVG_ACTIVE: `${BASE_URL_ANALYTIC}/overview/average-consumer-active/share`,
  SHARE_OVERVIEW_AVG_VISITING: `${BASE_URL_ANALYTIC}/overview/average-consumer-visit/share`,
  SHARE_OVERVIEW_AVG_ENGAGED: `${BASE_URL_ANALYTIC}/overview/average-consumer-engage/share`,
  
  CONSUMER_COUNTER: `${BASE_URL_ANALYTIC}/consumer/count-all`,

  CONTENT_OFFER_TOTAL: `${BASE_URL_ANALYTIC}/content-offer/total`,
  CONTENT_OFFER_CLICK_REDEEM: `${BASE_URL_ANALYTIC}/content-offer/click-redeem`,
  CONTENT_OFFER_TOP_REDEEM: `${BASE_URL_ANALYTIC}/content-offer/top-redeem`,
  CONTENT_OFFER_BOTTOM_REDEEM: `${BASE_URL_ANALYTIC}/content-offer/bottom-redeem`,
  CONTENT_OFFER_TOP_SALES: `${BASE_URL_ANALYTIC}/content-offer/top-sales-value`,
  CONTENT_OFFER_BOTTON_SALES: `${BASE_URL_ANALYTIC}/content-offer/bottom-sales-value`,
  CONTENT_OFFER_TOP_MEMBER: `${BASE_URL_ANALYTIC}/content-offer/top-redeem`,
  CONTENT_OFFER_TOP_AVG: `${BASE_URL_ANALYTIC}/content-offer/top-avg-transaction`,
  EXPORT_OFFER_CLICK_REDEEM: param => `${BASE_URL_ANALYTIC}/content-offer/click-redeem/${param}`,
  EXPORT_OFFER_TOP_REDEEM: param => `${BASE_URL_ANALYTIC}/content-offer/top-redeem/${param}`,
  EXPORT_OFFER_BOTTOM_REDEEM: param => `${BASE_URL_ANALYTIC}/content-offer/bottom-redeem/${param}`,
  EXPORT_OFFER_TOP_SALES: param => `${BASE_URL_ANALYTIC}/content-offer/top-sales-value/${param}`,
  EXPORT_OFFER_BOTTON_SALES: param => `${BASE_URL_ANALYTIC}/content-offer/bottom-sales-value/${param}`,
  EXPORT_OFFER_TOP_AVG: param => `${BASE_URL_ANALYTIC}/content-offer/top-avg-transaction/${param}`,
  EXPORT_OFFER_COUNTER: param => `${BASE_URL_ANALYTIC}/content-offer/total/${param}`,
  SHARE_COUNTER_OFFER: `${BASE_URL_ANALYTIC}/content-offer/total/share`,
  SHARE_OFFER_CLICK_REDEEM: `${BASE_URL_ANALYTIC}/content-offer/click-redeem/share`,
  SHARE_OFFER_TOP_REDEEM: `${BASE_URL_ANALYTIC}/content-offer/top-redeem/share`,
  SHARE_OFFER_BOTTOM_REDEEM: `${BASE_URL_ANALYTIC}/content-offer/bottom-redeem/share`,
  SHARE_OFFER_TOP_SALES: `${BASE_URL_ANALYTIC}/content-offer/top-sales-value/share`,
  SHARE_OFFER_BOTTON_SALE: `${BASE_URL_ANALYTIC}/content-offer/bottom-sales-value/share`,
  SHARE_OFFER_TOP_AVG: `${BASE_URL_ANALYTIC}/content-offer/top-avg-transaction/share`,

  CUSTOMER_COUNT: `${BASE_URL_ANALYTIC}/consumer/count-all`,
  CUSTOMER_TYPE: `${BASE_URL_ANALYTIC}/consumer/device-type`,
  CUSTOMER_TREND: `${BASE_URL_ANALYTIC}/consumer/device-trend`,
  CUSTOMER_GENDER: `${BASE_URL_ANALYTIC}/consumer/active-gender`,
  CUSTOMER_DEMOGRAPHIC: `${BASE_URL_ANALYTIC}/consumer/active-demographic`,
  CUSTOMER_DNR: `${BASE_URL_ANALYTIC}/consumer/compare-dnr`,
  CUSTOMER_AVE: `${BASE_URL_ANALYTIC}/consumer/compare-ave`,
  EXPORT_CUSTOMER_COUNT: param => `${BASE_URL_ANALYTIC}/consumer/count-all/${param}`,
  EXPORT_CUSTOMER_TYPE: param => `${BASE_URL_ANALYTIC}/consumer/device-type/${param}`,
  EXPORT_CUSTOMER_TREND: param => `${BASE_URL_ANALYTIC}/consumer/device-trend/${param}`,
  EXPORT_CUSTOMER_GENDER: param => `${BASE_URL_ANALYTIC}/consumer/active-gender/${param}`,
  EXPORT_CUSTOMER_DEMOGRAPHIC: param => `${BASE_URL_ANALYTIC}/consumer/active-demographic/${param}`,
  EXPORT_CUSTOMER_DNR: param => `${BASE_URL_ANALYTIC}/consumer/compare-dnr/${param}`,
  EXPORT_CUSTOMER_AVE: param => `${BASE_URL_ANALYTIC}/consumer/compare-ave/${param}`,
  SHARE_COUNTER_CUSTOMER: `${BASE_URL_ANALYTIC}/consumer/count-all/share`,
  SHARE_CUSTOMER_TYPE: `${BASE_URL_ANALYTIC}/consumer/device-type/share`,
  SHARE_CUSTOMER_TREND: `${BASE_URL_ANALYTIC}/consumer/device-trend/share`,
  SHARE_CUSTOMER_GENDER: `${BASE_URL_ANALYTIC}/consumer/active-gender/share`,
  SHARE_CUSTOMER_DEMOGRAPHIC: `${BASE_URL_ANALYTIC}/consumer/active-demographic/share`,
  SHARE_CUSTOMER_DNR: `${BASE_URL_ANALYTIC}/consumer/compare-dnr/share`,
  SHARE_CUSTOMER_AVE: `${BASE_URL_ANALYTIC}/consumer/compare-ave/share`,

  SHARE_SALES_SUMMARY: `${BASE_URL_ANALYTIC}/sales/summary/share`,
  SHARE_SALES_TOP: `${BASE_URL_ANALYTIC}/sales/top-store/share`,
  SHARE_SALES_BOTTOM: `${BASE_URL_ANALYTIC}/sales/bottom-store/share`,

  SHARE_HELP_USER_GUIDE: `${BASE_URL_ANALYTIC}/help/user-guide/share`,
  SHARE_HELP_FAQ: `${BASE_URL_ANALYTIC}/help/faq/share`,
  SHARE_HELP_METRIC: `${BASE_URL_ANALYTIC}/help/metric/share`
  
}

export const API_SALES = {
  SUMMARY: `${BASE_URL_ANALYTIC}/sales/summary`,
  TOP_STORE: `${BASE_URL_ANALYTIC}/sales/top-store`,
  BOTTOM_STORE: `${BASE_URL_ANALYTIC}/sales/bottom-store`,
  EXPORT_SALES_SUMMARY: param => `${BASE_URL_ANALYTIC}/sales/summary/${param}`,
  EXPORT_TOP_STORE: param => `${BASE_URL_ANALYTIC}/sales/top-store/${param}`,
  EXPORT_BOTTOM_STORE: param => `${BASE_URL_ANALYTIC}/sales/bottom-store/${param}`
}

export const API_USER_MANAGEMENT = {
  DETAIL_PROFILE: `${BASE_URL_ACCOUNT}/user/profile`,
  LIST_ROLE: `${BASE_URL_ACCOUNT}/role/list`
}
export const API_USER = {
  UPDATE_USER_PROFILE:  `${BASE_URL_ACCOUNT}/user/profile`
}
