import { createRouter, createWebHistory } from "vue-router"
import { isLogin } from '@/utils/auth'
import EmptyPageLayout from '@/components/layouts/EmptyPage'
import Login from '@/components/pages/Login'

const routes = [
  {
    path: '/',
    component: EmptyPageLayout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      },
      {
        path: '/forgot-password',
        name: 'Forgot',
        component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/Forgot'),
        meta: {
          public: true,
          onlyWhenLoggedOut: true
        }
      }
    ]
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin-layout" */ '@/components/layouts/Admin'),
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: () => import(/* webpackChunkName: "overview" */ '@/components/pages/admin/overviews/index')
      },
      {
        path: 'overview/total-details/:typeDetail',
        name: 'OverviewsTotalDetails',
        component: () => import(/* webpackChunkName: "overviews-total-details" */ '@/components/pages/admin/overviews/total-section/detail')
      },
      // {
      //   path: 'overview/total-details/table',
      //   name: 'OverviewsTotalDetailsTable',
      //   component: () => import(/* webpackChunkName: "overviews-total-details-table" */ '@/components/pages/admin/overviews/detail/detail-total/index')
      // },
      {
        path: 'overview/download-details/:typeDetail',
        name: 'OverviewsDownloadDetails',
        component: () => import(/* webpackChunkName: "overviews-download-details" */ '@/components/pages/admin/overviews/downloads/detail')
      },
      // {
      //   path: 'overview/download-details/table',
      //   name: 'OverviewsDownloadDetailsTable',
      //   component: () => import(/* webpackChunkName: "overviews-download-details-table" */ '@/components/pages/admin/overviews/detail/download/index')
      // },
      {
        path: 'overview/all-active-users-details/:typeDetail',
        name: 'OverviewsAllActiveUsersDetails',
        component: () => import(/* webpackChunkName: "overviews-all-active-users-details" */ '@/components/pages/admin/overviews/all-active-users/detail')
      },
      // {
      //   path: 'overview/all-active-users-details/table',
      //   name: 'OverviewsAllActiveUsersDetailsTable',
      //   component: () => import(/* webpackChunkName: "overviews-all-active-users-details-table" */ '@/components/pages/admin/overviews/detail/all-active-users/index')
      // },
      {
        path: 'overview/new-open-details/:typeDetail',
        name: 'OverviewsNewOpenDetails',
        component: () => import(/* webpackChunkName: "overviews-new-open-details" */ '@/components/pages/admin/overviews/new-opens/detail')
      },
      // {
      //   path: 'overview/new-open-details/table',
      //   name: 'OverviewsNewOpenDetailsTable',
      //   component: () => import(/* webpackChunkName: "overviews-new-open-details-table" */ '@/components/pages/admin/overviews/detail/new-opens/index')
      // },
      {
        path: 'overview/visiting-users-details/:typeDetail',
        name: 'OverviewsVisitingUsersDetails',
        component: () => import(/* webpackChunkName: "overviews-visiting-users-details" */ '@/components/pages/admin/overviews/visiting-users/detail')
      },
      // {
      //   path: 'overview/visiting-users-details/table',
      //   name: 'OverviewsVisitingUsersDetailsTable',
      //   component: () => import(/* webpackChunkName: "overviews-visiting-users-details-table" */ '@/components/pages/admin/overviews/detail/visiting-users/index')
      // },
      {
        path: 'overview/registrations-details/:typeDetail',
        name: 'OverviewsRegistrationDetails',
        component: () => import(/* webpackChunkName: "overviews-registrations-details" */ '@/components/pages/admin/overviews/registrations/detail')
      },
      // {
      //   path: 'overview/registrations-details/table',
      //   name: 'OverviewsRegistrationDetailsTable',
      //   component: () => import(/* webpackChunkName: "overviews-registrations-details-table" */ '@/components/pages/admin/overviews/detail/registrations/index')
      // },
      {
        path: 'overview/all-engaged-users-details/:typeDetail',
        name: 'OverviewsAllEngagedUsersDetails',
        component: () => import(/* webpackChunkName: "overviews-all-engaged-users-details" */ '@/components/pages/admin/overviews/all-engaged-users/detail')
      },
      // {
      //   path: 'overview/all-engaged-users-details/table',
      //   name: 'OverviewsAllEngagedUsersDetailsTable',
      //   component: () => import(/* webpackChunkName: "overviews-all-engaged-users-details-table" */ '@/components/pages/admin/overviews/detail/all-engaged-users/index')
      // },
      {
        path: 'overview/overall-unique-transaction-details/:typeDetail',
        name: 'OverviewsOverallUniqueTransactionDetails',
        component: () => import(/* webpackChunkName: "overviews-overall-unique-transaction-details" */ '@/components/pages/admin/overviews/overal-unique-transaction/detail')
      },
      // {
      //   path: 'overview/overall-unique-transaction-details/table',
      //   name: 'OverviewsOverallUniqueTransactionDetailsTable',
      //   component: () => import(/* webpackChunkName: "overviews-overall-unique-transaction-details-table" */ '@/components/pages/admin/overviews/detail/overal-unique-transaction/index')
      // },
      {
        path: 'marketing-campaign',
        name: 'MarketingCampaign',
        component: () => import(/* webpackChunkName: "marketing-campaign" */ '@/components/pages/admin/marketing-campaign/index')
      },
      {
        path: 'marketing-campaign/total-details/:typeDetail',
        name: 'MarketingCampaignTotalDetails',
        component: () => import(/* webpackChunkName: "marketing-campaign-total-details" */ '@/components/pages/admin/marketing-campaign/total-section/detail')
      },
      // {
      //   path: 'marketing-campaign/total-details/table',
      //   name: 'MarketingCampaignTotalDetailsTable',
      //   component: () => import(/* webpackChunkName: "marketing-campaign-total-details-table" */ '@/components/pages/admin/marketing-campaign/detail/detail-total/index')
      // },
      {
        path: 'marketing-campaign/top-offers-details/:typeDetail',
        name: 'MarketingCampaignTopOffersDetails',
        component: () => import(/* webpackChunkName: "marketing-campaign-top-offers-details" */ '@/components/pages/admin/marketing-campaign/top-campaign-offers/detail')
      },
      // {
      //   path: 'marketing-campaign/top-offers-details/table',
      //   name: 'MarketingCampaignTopOffersDetailsTable',
      //   component: () => import(/* webpackChunkName: "marketing-campaign-top-offers-details-table" */ '@/components/pages/admin/marketing-campaign/detail/top-campaign-offers/index')
      // },
      {
        path: 'marketing-campaign/top-banners-details/:typeDetail',
        name: 'MarketingCampaignTopBannersDetails',
        component: () => import(/* webpackChunkName: "marketing-campaign-top-banners-details" */ '@/components/pages/admin/marketing-campaign/top-campaign-banners/detail')
      },
      // {
      //   path: 'marketing-campaign/top-banners-details/table',
      //   name: 'MarketingCampaignTopBannersDetailsTable',
      //   component: () => import(/* webpackChunkName: "marketing-campaign-top-banners-details-table" */ '@/components/pages/admin/marketing-campaign/detail/top-campaign-banners/index')
      // },
      {
        path: 'marketing-campaign/unique-transaction-details/:typeDetail',
        name: 'MarketingCampaignUniqueTransactionDetails',
        component: () => import(/* webpackChunkName: "marketing-campaign-unique-transaction-details" */ '@/components/pages/admin/marketing-campaign/unique-transaction-campaign/detail')
      },
      // {
      //   path: 'marketing-campaign/unique-transaction-details/table',
      //   name: 'MarketingCampaignUniqueTransactionDetailsTable',
      //   component: () => import(/* webpackChunkName: "marketing-campaign-unique-transaction-details-table" */ '@/components/pages/admin/marketing-campaign/detail/unique-transaction-campaign/index')
      // },
      {
        path: 'marketing-campaign/sales-by-campaign-details/:typeDetail',
        name: 'MarketingCampaignSalesByCampaignDetails',
        component: () => import(/* webpackChunkName: "marketing-campaign-sales-by-campaign-details" */ '@/components/pages/admin/marketing-campaign/sales-by-campaign/detail')
      },
      // {
      //   path: 'marketing-campaign/sales-by-campaign-details/table',
      //   name: 'MarketingCampaignSalesByCampaignDetailsTable',
      //   component: () => import(/* webpackChunkName: "marketing-campaign-sales-by-campaign-details-table" */ '@/components/pages/admin/marketing-campaign/detail/sales-by-campaign/index')
      // },
      {
        path: 'marketing-campaign/average-basket-transaction-details/:typeDetail',
        name: 'MarketingCampaignAverageBasketTransDetails',
        component: () => import(/* webpackChunkName: "marketing-campaign-average-basket-transaction-details" */ '@/components/pages/admin/marketing-campaign/average-basket-transaction/detail')
      },
      // {
      //   path: 'marketing-campaign/average-basket-transaction-details/table',
      //   name: 'MarketingCampaignAverageBasketTransDetailsTable',
      //   component: () => import(/* webpackChunkName: "marketing-campaign-average-basket-transaction-details-table" */ '@/components/pages/admin/marketing-campaign/detail/average-basket-transaction/index')
      // },
      {
        path: 'content',
        name: 'Content',
        component: () => import(/* webpackChunkName: "content" */ '@/components/pages/admin/content/index')
      },
      {
        path: 'help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "help" */ '@/components/pages/admin/help/index')
      },
      {
        path: 'content/offer-total-details/:typeDetail',
        name: 'ContentOfferTotalDetails',
        component: () => import(/* webpackChunkName: "content-offer-total-details" */ '@/components/pages/admin/content/offers/total-section/detail')
      },
      {
        path: 'content/offer-clicks-redemptions-details/:typeDetail',
        name: 'ContentOfferClicksRedemptionsDetails',
        component: () => import(/* webpackChunkName: "content-offer-clicks-redemptions-details" */ '@/components/pages/admin/content/offers/offer-clicks-and-redemptions/detail')
      },
      {
        path: 'content/offer-top-redemptions-details/:typeDetail',
        name: 'ContentTopOfferRedemptionsDetails',
        component: () => import(/* webpackChunkName: "content-offer-top-redemptions-details" */ '@/components/pages/admin/content/offers/top-offer-redemptions/detail')
      },
      {
        path: 'content/offer-bottom-redemptions-details/:typeDetail',
        name: 'ContentBottomOfferRedemptionsDetails',
        component: () => import(/* webpackChunkName: "content-offer-bottom-redemptions-details" */ '@/components/pages/admin/content/offers/bottom-offer-redemptions/detail')
      },
      {
        path: 'content/offer-top-sales-value-details/:typeDetail',
        name: 'ContentTopOfferSalesDetails',
        component: () => import(/* webpackChunkName: "content-offer-top-sales-value-details" */ '@/components/pages/admin/content/offers/top-offer-sales-value/detail')
      },
      {
        path: 'content/offer-bottom-sales-value-details/:typeDetail',
        name: 'ContentBottomOfferSalesDetails',
        component: () => import(/* webpackChunkName: "content-offer-bottom-sales-value-details" */ '@/components/pages/admin/content/offers/bottom-offer-sales-value/detail')
      },
      {
        path: 'content/offer-top-member-transaction-details/:typeDetail',
        name: 'ContentTopMemberTransactionOfferDetails',
        component: () => import(/* webpackChunkName: "content-offer-transaction-details" */ '@/components/pages/admin/content/offers/top-member-transaction-offer/detail')
      },
      {
        path: 'content/offer-top-member-basket-offer-details/:typeDetail',
        name: 'ContentTopMemberBasketOfferDetails',
        component: () => import(/* webpackChunkName: "content-offer-top-member-basket-details" */ '@/components/pages/admin/content/offers/top-basket-offer/detail')
      },
      {
        path: 'content/banner-total-details/:typeDetail',
        name: 'ContentBannerTotalDetail',
        props: true,
        component: () => import(/* webpackChunkName: "content-banner-total-details" */ '@/components/pages/admin/content/banners/total-section/detail')
      },
      {
        path: 'content/banner-impressions-clicks-details/:typeDetail',
        name: 'ContentBannerImpressionsClicksDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-banner-impressions-clicks-details" */ '@/components/pages/admin/content/banners/banner-impressions-clicks/detail')
      },
      {
        path: 'content/banner-top-impressions-details/:typeDetail',
        name: 'ContentTopBannerImpressionsDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-banner-top-impressions-details" */ '@/components/pages/admin/content/banners/top-banners-impressions/detail')
      },
      {
        path: 'content/banner-top-clicks-details/:typeDetail',
        name: 'ContentTopBannerClicksDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-banner-top-clicks-details" */ '@/components/pages/admin/content/banners/top-banners-clicks/detail')
      },
      {
        path: 'content/banner-bottom-clicks-details/:typeDetail',
        name: 'ContentBottomBannerClicksDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-banner-bottom-clicks-details" */ '@/components/pages/admin/content/banners/bottom-banners-clicks/detail')
      },
      {
        path: 'content/banner-bottom-impressions-details/:typeDetail',
        name: 'ContentBottomBannerImpressionsDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-banner-bottom-redemptions-details" */ '@/components/pages/admin/content/banners/bottom-banners-impressions/detail')
      },
      {
        path: 'content/messages-total-details/:typeDetail',
        name: 'ContentMessagesTotalDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-messages-total-details" */ '@/components/pages/admin/content/messages/total-section/detail')
      },
      {
        path: 'content/messages-sent-impressions-clicks-details/:typeDetail',
        name: 'ContentMessagesSentImpressionsClicksDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-messages-sent-impressions-clicks-details" */ '@/components/pages/admin/content/messages/message-sent-impressions-clicks/detail')
      },
      {
        path: 'content/messages-click-rate-details/:typeDetail',
        name: 'ContentMessagesClickRateDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-messages-click-rate-details" */ '@/components/pages/admin/content/messages/message-click-rate/detail')
      },
      {
        path: 'content/messages-top-messages-impressions-details/:typeDetail',
        name: 'ContentMessagesTopMessagesImpressionsDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-messages-top-messages-impressions-details" */ '@/components/pages/admin/content/messages/top-messages-impressions/detail')
      },
      {
        path: 'content/messages-top-messages-clicks-details/:typeDetail',
        name: 'ContentMessagesTopMessagesClicksDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-messages-top-messages-clicks-details" */ '@/components/pages/admin/content/messages/top-messages-clicks/detail')
      },
      {
        path: 'content/messages-bottom-messages-impressions-details/:typeDetail',
        name: 'ContentMessagesBottomMessagesImpressionsDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-messages-bottom-messages-impressions-details" */ '@/components/pages/admin/content/messages/bottom-messages-impressions/detail')
      },
      {
        path: 'content/messages-bottom-messages-clicks-details/:typeDetail',
        name: 'ContentMessagesBottomMessagesClicksDetails',
        props: true,
        component: () => import(/* webpackChunkName: "content-messages-bottom-messages-clicks-details" */ '@/components/pages/admin/content/messages/bottom-messages-clicks/detail')
      },
      {
        path: 'customers',
        name: 'Customers',
        component: () => import(/* webpackChunkName: "customers" */ '@/components/pages/admin/customers/index')
      },
      {
        path: 'customers/total-details/:typeDetail',
        name: 'CustomersTotalDetails',
        component: () => import(/* webpackChunkName: "customers-total-details" */ '@/components/pages/admin/customers/total-section/detail')
      },
      {
        path: 'customers/downloads-newdevices-registrations-details/:typeDetail',
        name: 'CustomersDownloadNewDevicesRegisrations',
        component: () => import(/* webpackChunkName: "customers-downloads-newdevices-registrations-details" */ '@/components/pages/admin/customers/downloads-newdevices-registrations/detail')
      },
      {
        path: 'customers/active-visiting-engaged-users-details/:typeDetail',
        name: 'CustomersActiveVisitingEngagedUsersDetails',
        component: () => import(/* webpackChunkName: "customers-active-visiting-engaged-users-details" */ '@/components/pages/admin/customers/active-visiting-engaged-users/detail')
      },
      // {
      //   path: 'customers/active-visiting-engaged-users-details/table',
      //   name: 'CustomersActiveVisitingEngagedUsersDetailsTable',
      //   component: () => import(/* webpackChunkName: "customers-active-visiting-engaged-users-details-table" */ '@/components/pages/admin/customers/detail/active-visiting-engaged-users/index')
      // },
      {
        path: 'customers/active-users-demographics-details/:typeDetail',
        name: 'CustomersActiveUsersDemographics',
        component: () => import(/* webpackChunkName: "customers-active-users-demographics-details" */ '@/components/pages/admin/customers/active-users-demographics/detail')
      },
      // {
      //   path: 'customers/active-users-demographics-details/table',
      //   name: 'CustomersActiveUsersDemographicsTable',
      //   component: () => import(/* webpackChunkName: "customers-active-users-demographics-details-table" */ '@/components/pages/admin/customers/detail/active-users-demographics/index')
      // },
      {
        path: 'customers/active-users-gender-trends-details/:typeDetail',
        name: 'CustomersActiveUsersGenderTrendsDetails',
        component: () => import(/* webpackChunkName: "customers-active-users-gender-trends-details" */ '@/components/pages/admin/customers/active-users-gender-trends/detail')
      },
      // {
      //   path: 'customers/active-users-gender-trends-details/table',
      //   name: 'CustomersActiveUsersGenderTrendsDetailsTable',
      //   component: () => import(/* webpackChunkName: "customers-active-users-gender-trends-details-table" */ '@/components/pages/admin/customers/detail/active-user-gender-trends/index')
      // },
      {
        path: 'customers/device-type-details/:typeDetail',
        name: 'CustomersDeviceTypeDetails',
        component: () => import(/* webpackChunkName: "customers-device-type-details" */ '@/components/pages/admin/customers/device-type/detail')
      },
      // {
      //   path: 'customers/device-type-details/table',
      //   name: 'CustomersDeviceTypeDetailsTable',
      //   component: () => import(/* webpackChunkName: "customers-device-type-details-table" */ '@/components/pages/admin/customers/detail/device-type/index')
      // },
      {
        path: 'customers/device-type-trend-details/:typeDetail',
        name: 'CustomersDeviceTypeTrendDetails',
        component: () => import(/* webpackChunkName: "customers-device-type-trend-details-details" */ '@/components/pages/admin/customers/device-type-trend/detail')
      },
      // {
      //   path: 'customers/device-type-trend-details/table',
      //   name: 'CustomersDeviceTypeTrendDetailsTable',
      //   component: () => import(/* webpackChunkName: "customers-device-type-trend-details-details-table" */ '@/components/pages/admin/customers/detail/device-type-trends/index')
      // },
      {
        path: 'sales',
        name: 'Sales',
        component: () => import(/* webpackChunkName: "sales" */ '@/components/pages/admin/sales/index')
      },
      {
        path: 'sales/detail/:typeDetail',
        name: 'SalesDetail',
        props: true,
        component: () => import(/* webpackChunkName: "sales-detail" */ '@/components/pages/admin/sales/sales-summary/detail')
      },
      {
        path: 'sales/detail-top-store/:typeDetail',
        name: 'SalesTopStoreDetail',
        props: true,
        component: () => import(/* webpackChunkName: "sales-top-store-detail" */ '@/components/pages/admin/sales/sales-top-bottom/detail/top-rank-store')
      },
      {
        path: 'sales/detail-bottom-store/:typeDetail',
        name: 'SalesBottomStoreDetail',
        props: true,
        component: () => import(/* webpackChunkName: "sales-bottom-storedetail" */ '@/components/pages/admin/sales/sales-top-bottom/detail/bottom-rank-store')
      },
      {
        path: 'settings/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/setting/profile')
      },
      {
        path: 'settings/change-password',
        name: 'ChangePassword',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/setting/change-password')
      },
      {
        path: 'role/inactive',
        name: 'RoleInactive',
        component: () => import(/* webpackChunkName: "banners" */ '@/components/pages/admin/Inactive')
      },
      {
        path: 'unauthorized',
        name: 'Unauthorized',
        component: () => import(/* webpackChunkName: "unauthorized" */ '@/components/pages/Unauthorized')
      },
      {
        path: 'preview',
        name: 'Preview',
        component: () => import(/* webpackChunkName: "unauthorized" */ '@/components/globals/preview')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/components/pages/NotFound'),
    meta: {
      public: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(''),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = isLogin()

  if (!isPublic && !loggedIn) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/admin')
  }

  next()
})

export default router
