import {apiGetAuth, apiPostAuth} from '@/utils/api'
// import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_CONTENT } from '@/utils/api-url'
// import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    counter_customers: { data: [] },
    device_type: { data: [] },
    device_trend: { data: []},
    gender_trend: { data: []},
    demographic: { data: []},
    ave: { data: []},
    dnr: { data: []},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_COUNTER_CUSTOMER (state, payload) {
    state.counter_customers = payload
  },
  SET_CUSTOMER_DEVICE_TYPE (state, payload) {
    state.device_type = payload
  },
  SET_CUSTOMER_DEVICE_TREND (state, payload) {
    state.device_trend = payload
  },
  SET_CUSTOMER_GENDER_TREND (state, payload) {
    state.gender_trend = payload
  },
  SET_CUSTOMER_DEMOGRAPHIC(state, payload) {
    state.demographic = payload
  },
  SET_CUSTOMER_AVE(state, payload) {
    state.ave = payload
  },
  SET_CUSTOMER_DNR(state, payload) {
    state.dnr = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getCounterCustomer (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CUSTOMER_COUNT, payload)
      context.commit('SET_COUNTER_CUSTOMER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCustomerDeviceType (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CUSTOMER_TYPE, payload)
      context.commit('SET_CUSTOMER_DEVICE_TYPE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCustomerDeviceTrend (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CUSTOMER_TREND, payload)
      context.commit('SET_CUSTOMER_DEVICE_TREND', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCustomerGenderTrend (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CUSTOMER_GENDER, payload)
      context.commit('SET_CUSTOMER_GENDER_TREND', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCustomerDemographic (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CUSTOMER_DEMOGRAPHIC, payload)
      context.commit('SET_CUSTOMER_DEMOGRAPHIC', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCustomerAve (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CUSTOMER_AVE, payload)
      context.commit('SET_CUSTOMER_AVE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCustomerDnr (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CUSTOMER_DNR, payload)
      context.commit('SET_CUSTOMER_DNR', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerCounter (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_CUSTOMER_COUNT(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerGenderTrend (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_CUSTOMER_GENDER(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerDeviceType (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_CUSTOMER_TYPE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerDeviceTrend (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_CUSTOMER_TREND(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerDemographic (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_CUSTOMER_DEMOGRAPHIC(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerDnr (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_CUSTOMER_DNR(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerAve (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_CUSTOMER_AVE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingUniqueTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_MARKETING_UNIQUE_TRANSACTION(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingSales (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_MARKETING_SALES(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCounterCustomer (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_COUNTER_CUSTOMER, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerDnr(context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_CUSTOMER_DNR, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerAve(context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_CUSTOMER_AVE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerDemographic(context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_CUSTOMER_DEMOGRAPHIC, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerType(context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_CUSTOMER_TYPE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerTrend(context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_CUSTOMER_TREND, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerGender(context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_CUSTOMER_GENDER, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  counter_customers (state) {
    return state.counter_customers
  },
  device_trend (state) {
    return state.device_trend
  },
  device_type (state) {
    return state.device_type
  },
  demographic (state) {
    return state.demographic
  },
  ave (state) {
    return state.ave
  },
  dnr (state) {
    return state.dnr
  },
  gender_trend (state) {
    return state.gender_trend
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
