import {apiGetAuth, apiPostAuth} from '@/utils/api'
// import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_CONTENT } from '@/utils/api-url'
// import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    counter_overview: { data: [] },
    overview_open: { data: [] },
    overview_registration: { data: []},
    overview_avg_active: { data: []},
    overview_avg_visiting: { data: []},
    overview_avg_engaged: { data: []},
    overview_active: { data: []},
    overview_download: { data: []},
    overview_visiting: { data: []},
    overview_engaged: { data: []},
    overview_transaction: { data: []},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_COUNTER_OVERVIEW (state, payload) {
    state.counter_overview = payload
  },
  SET_OVERVIEW_OPEN (state, payload) {
    state.overview_open = payload
  },
  SET_OVERVIEW_REGISTRATION (state, payload) {
    state.overview_registration = payload
  },
  SET_OVERVIEW_ACTIVE (state, payload) {
    state.overview_active = payload
  },
  SET_OVERVIEW_DOWNLOAD (state, payload) {
    state.overview_download = payload
  },
  SET_OVERVIEW_VISITING (state, payload) {
    state.overview_visiting = payload
  },
  SET_OVERVIEW_ENGAGED (state, payload) {
    state.overview_engaged = payload
  },
  SET_OVERVIEW_AVG_ACTIVE (state, payload) {
    state.overview_avg_active = payload
  },
  SET_OVERVIEW_AVG_VISITING (state, payload) {
    state.overview_avg_visiting = payload
  },
  SET_OVERVIEW_AVG_ENGAGED (state, payload) {
    state.overview_avg_engaged = payload
  },
  SET_OVERVIEW_TRANSACTION (state, payload) {
    state.overview_transaction = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getOverviewCounter (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_TOTAL, payload)
      context.commit('SET_COUNTER_OVERVIEW', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewOpen (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_OPEN, payload)
      context.commit('SET_OVERVIEW_OPEN', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewEngaged (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_ENGAGED, payload)
      context.commit('SET_OVERVIEW_ENGAGED', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_TRANSACTION, payload)
      context.commit('SET_OVERVIEW_TRANSACTION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewActive (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_ACTIVE, payload)
      context.commit('SET_OVERVIEW_ACTIVE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewDownload (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_DOWNLOAD, payload)
      context.commit('SET_OVERVIEW_DOWNLOAD', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewVisiting (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_VISITING, payload)
      context.commit('SET_OVERVIEW_VISITING', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewRegistration (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_REGISTRATION, payload)
      context.commit('SET_OVERVIEW_REGISTRATION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewAvgActive (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_AVG_ACTIVE, payload)
      context.commit('SET_OVERVIEW_AVG_ACTIVE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewAvgVisiting (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_AVG_VISITING, payload)
      context.commit('SET_OVERVIEW_AVG_VISITING', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOverviewAvgEngaged (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_AVG_ENGAGED, payload)
      context.commit('SET_OVERVIEW_AVG_ENGAGED', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewAvgActive (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_AVG_ACTIVE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewAvgVisiting (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_AVG_VISITING, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewAvgEngaged (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_AVG_ENGAGED, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCounterOverview (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_COUNTER_OVERVIEW, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewActive (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_ACTIVE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewOpen (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_OPEN, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewVisiting (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_VISITING, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewDownload (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_DOWNLOAD, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewRegistration (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_REGISTRATION, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewTransaction (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_UNIQUE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOverviewEngaged (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OVERVIEW_ENGAGED, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareHelpUserGuide (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_HELP_USER_GUIDE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareHelpFaq (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_HELP_FAQ, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareHelpMetric (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_HELP_METRIC, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTOverviewAvgActive (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_AVG_ACTIVE(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewAvgVisiting (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_AVG_VISITING(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewAvgEngaged (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_AVG_ENGAGED(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewActive (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_ACTIVE(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewCount (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_COUNTER(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewOpen (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_OPEN(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewRegistration (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_REGISTRATION(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewDownload (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_DOWNLOAD(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewEngaged (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_ENGAGED(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_TRANSACTION(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  },
  async exportCSVTXTOverviewVisiting (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.OVERVIEW_EXPORT_VISITING(payload.param), payload.reqBody)
      return response
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  }
}

const getters = {
  counter_overview (state) {
    return state.counter_overview
  },
  overview_open (state) {
    return state.overview_open
  },
  overview_active (state) {
    return state.overview_active
  },
  overview_engaged (state) {
    return state.overview_engaged
  },
  overview_visiting (state) {
    return state.overview_visiting
  },
  overview_transaction (state) {
    return state.overview_transaction
  },
  overview_registration (state) {
    return state.overview_registration
  },
  overview_download (state) {
    return state.overview_download
  },
  overview_avg_active (state) {
    return state.overview_avg_active
  },
  overview_avg_visiting (state) {
    return state.overview_avg_visiting
  },
  overview_avg_engaged (state) {
    return state.overview_avg_engaged
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
