<template>
  <div class="uk-background-cover uk-flex uk-flex-center uk-height-1-1 layout-background">
    <router-view />
  </div>
</template>

<style scoped>
.layout-background {
    background-color: #fafafa
}
</style>
