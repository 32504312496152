import { createStore } from "vuex"
import auth from './modules/auth'
import user from './modules/user'
import global from './modules/global'
import banner from './modules/content/banner'
import message from './modules/content/message'
import marketing from './modules/marketing'
import overview from './modules/overview'
import offer from './modules/content/offer'
import sales from './modules/sales'
import customer from './modules/customers'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    global,
    banner,
    message,
    marketing,
    overview,
    offer,
    sales,
    customer
  }
})
