import {apiGetAuth, apiPostAuth} from '@/utils/api'
// import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_CONTENT } from '@/utils/api-url'
// import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    counter_offer: { data: [] },
    offer_bottom_redeem: { data: [] },
    offer_top_redeem: { data: [] },
    offer_top_member: { data: []},
    offer_top_avg: { data: []},
    offer_click_redeem: { data: []},
    offer_bottom_sales: { data: []},
    offer_top_sales: { data: []},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_CONTENT_OFFER_TOTAL (state, payload) {
    state.counter_offer = payload
  },
  SET_CONTENT_OFFER_BOTTOM_REDEEM (state, payload) {
    state.offer_bottom_redeem = payload
  },
  SET_CONTENT_OFFER_TOP_REDEEM (state, payload) {
    state.offer_top_redeem = payload
  },
  SET_CONTENT_OFFER_TOP_MEMBER (state, payload) {
    state.offer_top_member = payload
  },
  SET_CONTENT_OFFER_TOP_AVG (state, payload) {
    state.offer_top_avg = payload
  },
  SET_CONTENT_OFFER_CLICK_REDEEM (state, payload) {
    state.offer_click_redeem = payload
  },
  SET_CONTENT_OFFER_BOTTOM_SALES (state, payload) {
    state.offer_bottom_sales = payload
  },
  SET_CONTENT_OFFER_TOP_SALES (state, payload) {
    state.offer_top_sales = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getCounterOffer (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CONTENT_OFFER_TOTAL, payload)
      context.commit('SET_CONTENT_OFFER_TOTAL', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOfferBottomRedeem (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CONTENT_OFFER_BOTTOM_REDEEM, payload)
      context.commit('SET_CONTENT_OFFER_BOTTOM_REDEEM', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOfferTopRedeem (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CONTENT_OFFER_TOP_REDEEM, payload)
      context.commit('SET_CONTENT_OFFER_TOP_REDEEM', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOfferTopMember (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CONTENT_OFFER_TOP_MEMBER, payload)
      context.commit('SET_CONTENT_OFFER_TOP_MEMBER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOfferTopAvg (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CONTENT_OFFER_TOP_AVG, payload)
      if (response?.data?.data) {
        const rankUpdate = response.data.data.sort(function(a, b){
          return b.total_avg_sales - a.total_avg_sales
        })

        var rank = 1
        for (var i = 0; i < rankUpdate.length; i++) {
          if (i > 0 && rankUpdate[i].total_avg_sales < rankUpdate[i - 1].total_avg_sales) {
            rank++
          }
          rankUpdate[i].rank = rank
        }
        context.commit('SET_CONTENT_OFFER_TOP_AVG', rankUpdate)
      } else {
        context.commit('SET_CONTENT_OFFER_TOP_AVG', response.data.data)
      }
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOfferClickRedeem (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CONTENT_OFFER_CLICK_REDEEM, payload)
      context.commit('SET_CONTENT_OFFER_CLICK_REDEEM', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOfferBottomSales (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CONTENT_OFFER_BOTTON_SALES, payload)
      context.commit('SET_CONTENT_OFFER_BOTTOM_SALES', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOfferTopSales (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.CONTENT_OFFER_TOP_SALES, payload)
      context.commit('SET_CONTENT_OFFER_TOP_SALES', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCounterOffer (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_COUNTER_OFFER, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOfferBottomRedeem (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OFFER_BOTTOM_REDEEM, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOfferBottomSales (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OFFER_BOTTON_SALE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOfferTopSales (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OFFER_TOP_SALES, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOfferTopRedeem (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OFFER_TOP_REDEEM, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOfferTopAvg (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OFFER_TOP_AVG, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareOfferClickRedeem (context, payload) {
    try {
      const response = await apiPostAuth(API_CONTENT.SHARE_OFFER_CLICK_REDEEM, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTOfferBottomRedeem (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_OFFER_BOTTOM_REDEEM(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTOfferBottomSales (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_OFFER_BOTTON_SALES(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTOfferTopRedeem (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_OFFER_TOP_REDEEM(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTOfferTopSales (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_OFFER_TOP_SALES(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTOfferCounter (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_OFFER_COUNTER(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTOfferTopAvg (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_OFFER_TOP_AVG(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTOfferClickRedeem (context, payload) {
    try {
      const response = await apiGetAuth(API_CONTENT.EXPORT_OFFER_CLICK_REDEEM(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  counter_offer (state) {
    return state.counter_offer
  },
  offer_click_redeem (state) {
    return state.offer_click_redeem
  },
  offer_top_redeem (state) {
    return state.offer_top_redeem
  },
  offer_bottom_redeem (state) {
    return state.offer_bottom_redeem
  },
  offer_top_avg (state) {
    return state.offer_top_avg
  },
  offer_top_sales (state) {
    return state.offer_top_sales
  },
  offer_top_member (state) {
    return state.offer_top_member
  },
  offer_bottom_sales (state) {
    return state.offer_bottom_sales
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
