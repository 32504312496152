import { apiGetAuth } from '@/utils/api'
import { API_USER_MANAGEMENT } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    roles: { data: {}},
    detail_profile: { data: {}}
  }
}

const mutations = {
  SET_ROLE(state, payload) {
    state.roles = payload
  },
  SET_DETAIL_PROFILE(state, payload) {
    state.detail_profile = payload
  }
}

const actions = {
  async getListRole (context) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.LIST_ROLE)
      context.commit('SET_ROLE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailProfile (context) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.DETAIL_PROFILE)
      context.commit('SET_DETAIL_PROFILE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_role (state) {
    return state.roles
  },
  detail_profile (state) {
    return state.detail_profile
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
